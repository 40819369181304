// Extends
%menu-hover {
  color: var(--theme-primaryColor);
}

%displayBlock {
  display: block;
}

%displayNone {
  display: none;
}

%transition-effect {
  transition: 0.3s;
}

// Variables
$white-color: #fff;

// Scss classes starts
body {
  font-size: 14px;
  overflow: auto;
  color: var(--theme-secondaryColor);
  font-family: 'Montserrat', Helvetica, Arial, serif !important;
  background-color: #fff;
  overflow-x: hidden;

  ::placeholder {
    color: var(--theme-secondaryColor);
  }

  ::selection {
    background-color: var(--theme-primaryColor);
    color: #fff;
  }

  .custom-shadow {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }

  .shadow-card {
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  }

  h2 {
    font-size: 20px;
  }

  [type='text'],
  [type='email'],
  [type='password'],
  [type='date'],
  [type='number'],
  [type='time'],
  textarea,
  select,
  .css-1s2u09g-control,
  .css-1s2u09g-control:hover,
  .input-style {
    border-color: #d1d5db;
  }

  [type='search']:focus,
  input:focus {
    box-shadow: none !important;
  }

  .card-container {
    &:hover {
      transform: scale(1.1);
      box-shadow: 0 2px 8px 0 rgb(99 99 99 / 20%);
      transition: transform 0.5s;

      .card-top-h {
        span.block:first-child {
          color: #fff;
          font-size: 1.5rem;
        }
      }
    }
  }

  .form-height {
    min-height: 30rem;
  }

  @media (min-width: 1200px) {
    .container {
      width: 1062px;
    }
  }

  .checkbox:focus {
    box-shadow: none;
  }

  #main {
    @extend %transition-effect;
    width: calc(100% - 75px);

    @media screen and (max-width: 768px) {
      margin-left: 4rem;
    }
  }

  .sidebar {
    position: fixed;
    z-index: 1001;
    min-height: 100vh;
    width: 75px;
    @extend %transition-effect;
    box-shadow: rgb(0 0 0 / 10%) 0px 1px 3px 0px, rgb(0 0 0 / 6%) 0px 1px 2px 0px;
    overflow-y: hidden;

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #e3e3e3;
      border-radius: 0;
    }

    &::-webkit-scrollbar-track {
      background: #e3e3e3;
      border-radius: 0;
    }

    &:hover {
      overflow-y: auto;
    }

    .active-menu {
      background: #f5f5f5;
      border-radius: 6px;
    }

    .side-menu,
    .sub-menu-li {
      margin: 0 9px;
      padding: 0;
    }

    .side-menu,
    .inner-menus {
      height: 42px;
    }

    .side-menu {
      img {
        margin-left: 7px;
      }
    }

    .sub-menu-li {
      margin-top: 6px !important;
    }

    .active {
      background: var(--theme-primaryColor);
      border-radius: 6px;
      color: #fff;

      &:hover {
        color: #fff !important;
      }

      img {
        filter: brightness(5);
      }
    }

    i.fa-chevron-right {
      transition: 0.3s;
    }

    .active-menu {
      background: #f5f5f5;

      i.fa-chevron-right {
        transform: rotate(90deg);
      }
    }

    a {
      color: #625f6e;

      &:hover {
        color: #625f6e !important;
      }
    }

    &:hover {
      width: 235px;
    }

    .side-menu {
      @extend %transition-effect;
      position: relative;

      span + i {
        font-size: 10px;
      }

      &.active {
        span {
          color: #fff;
        }
      }

      span {
        margin-right: auto;
        color: #605c74;
      }

      i {
        color: #99999e;
      }

      &.active {
        color: #fff;

        i {
          color: #fff;
        }
      }
    }
  }

  .unpin {
    #main {
      margin-left: 75px;
    }

    .menu-h-icon {
      display: inline;
    }

    .sidebar ul li {
      text-align: center;
    }

    .sidebar ul li .side-menu img,
    .sidebar ul li .side-menu i.fa-cog {
      margin: 0 auto;
    }

    .side-menu span,
    .text-logo,
    .unpin-icon,
    .menu-h,
    .fa-chevron-right,
    .fa-chevron-down {
      display: none;
    }

    .sidebar:hover {
      .side-menu span,
      .text-logo,
      .unpin-icon,
      .menu-h,
      .fa-chevron-right {
        display: inline;
      }

      .menu-h-icon,
      .smalltext-logo {
        display: none;
      }

      ul li {
        text-align: left;

        .side-menu img,
        .side-menu i.fa-cog {
          margin: unset;
        }
      }
    }
  }

  .pin {
    .sidebar {
      @media screen and (min-width: 768px) {
        width: 235px;
      }

      .unpin-icon {
        @extend %displayNone;
      }

      .pin-icon {
        @extend %displayBlock;
      }
    }

    #main {
      margin-left: 235px;
      width: calc(100% - 235px);
    }

    .menu-h-icon,
    .smalltext-logo {
      display: none;
    }

    .fa-chevron-right {
      display: inline;
    }
  }

  .full-logo {
    width: 170px;
  }

  .sales-bg {
    background: rgba(115, 103, 240, 0.12);
  }

  .customer-bg {
    background: rgba(0, 207, 232, 0.12);
  }

  .product-bg {
    background: rgba(234, 84, 85, 0.12);
  }

  .revenue-bg {
    background: rgba(40, 199, 111, 0.12);
  }

  header {
    z-index: 999;
    position: fixed;
    background-color: $white-color;

    @media screen and (max-width: 768px) {
      width: calc(100% - 35px) !important;

      & * {
        display: none;
      }

      .current-usr {
        display: flex !important;
      }

      .current-usr * {
        display: block;
      }

      .profile-dropdown {
        display: block !important;

        ul {
          &.dropdown-menu {
            li,
            li * {
              display: block !important;
            }
          }
        }
      }
    }

    .active-usr {
      right: 0;
      top: 28px;
    }

    .notification {
      top: -4px;
      right: -1px;
    }
  }

  div {
    &.customshadow {
      height: 102px;
      z-index: 11;
      background: linear-gradient(
        180deg,
        hsla(0, 0%, 97.3%, 0.95) 44%,
        hsla(0, 0%, 97.3%, 0.46) 73%,
        hsla(0, 0%, 100%, 0)
      );
    }
  }

  div {
    &.show {
      .sidebar {
        padding-left: 5px;
      }

      &.pin {
        header {
          width: calc(100% - 295px);
        }
      }
    }

    &.unpin {
      header {
        width: calc(100% - 138px);
      }

      .smalltext-logo {
        display: block;
      }
    }
  }

  .dashboard-card {
    img {
      &:hover {
        transition: all 0.5s;
        transform: translateY(-10px);
      }
    }
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #e3e3e3;
    border-radius: 10px;
  }

  #siteFooter {
    background: none repeat scroll 0 0 white;
    border-top: 1px solid #e7eaec;
  }

  .backTotop {
    margin-left: auto;
    position: fixed;
    right: 10px;
    border: 0;
    bottom: 75px;
    cursor: pointer;
  }

  .settings {
    box-shadow: -3px 0 8px rgb(34 41 47 / 10%);
    width: 32px;
    padding: 0;
    margin: 1rem 18px;
  }

  @media screen and (max-width: 768px) {
    .sidebar {
      width: 0;

      .icons-nav {
        .side-menu {
          span + i {
            display: block;
          }
        }
      }

      .unpin-icon,
      .pin-icon {
        display: none !important;
      }
    }

    #main {
      margin-left: 0 !important;
      width: 100% !important;
    }

    .bgoverlay {
      .overlay {
        opacity: 0.8;
        background: rgba(34, 41, 47, 0.5);
        position: fixed;
        top: 0;
        height: 120vh;
        display: block;
        left: 0;
        right: 0;
        z-index: 1000;
      }

      .sidebar {
        width: 235px;

        .half-icon-logo {
          display: none !important;
        }

        .full-logo {
          display: block !important;
          height: 40px;
          width: 170px;
          object-fit: contain;
        }
      }
    }

    .bg-without-overlay {
      .sidebar {
        width: 0;
        display: none;
      }
    }

    .menu-bars {
      display: block;
    }
  }

  .pagination-item {
    &.dots {
      padding: 0.5rem 0;
    }
  }

  .css-1okebmr-indicatorSeparator {
    background: transparent;
  }

  .border-spacing {
    border-spacing: 10px 12px;
  }

  .unpin {
    #main {
      margin-left: 75px;
    }
  }

  .pin {
    #main {
      margin-left: 235px;
      width: calc(100% - 235px);
    }
  }

  .breadcrumb > li {
    display: inline-block;
  }

  .breadcrumb > li + li:before {
    padding: 0 5px;
    color: #ccc;
    content: '/\00a0';
  }

  #tabs-card {
    .dropdown {
      .fa-ellipsis-v {
        background: #9b9b9ba3;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
      }
    }
  }
}

iframe {
  @extend %displayNone;
}

.feature-div {
  iframe {
    display: block;
  }
}
.pdf-iframe {
  iframe {
    display: block;
  }
}

.custom-dropdown {
  max-height: 0;
  overflow: hidden;
  transition: max-height 1s;
}

.custom-dropdown.show {
  max-height: 500px;
}

.checkbox-tick {
  left: 10px;
  color: #fff;
}

.checkbox-cross {
  right: 10px;
  color: #625f6e;
}

.checkbox-cross,
.checkbox-tick {
  top: 9px;
  position: absolute;
  font-size: 14px;
  pointer-events: none;
}

.form-switch .form-check-input:checked + i.checkbox-tick {
  display: block;
}

.form-switch .form-check-input:checked + i.checkbox-tick + i.checkbox-cross {
  display: none;
}

.wrapper-class.rdw-editor-wrapper {
  border: 1px solid #f1f1f1;
  border-radius: 5px;

  .rdw-editor-toolbar {
    background-color: #fbfbfb;
    border: 0;
    border-bottom: 1px solid #f1f1f1;
  }

  .rdw-editor-main {
    padding: 6px 5px 0;
  }
}

.theme-settings {
  box-shadow: -3px 0 8px rgb(34 41 47 / 10%);
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  right: 0;
  background: var(--theme-primaryColor);
  text-align: center;
  cursor: pointer;
  color: #fff !important;
  position: fixed;
  top: 50%;
  width: 38px;
  height: 38px;
  line-height: 40px;
  z-index: 9;
}

[type='radio']:checked {
  background-image: unset !important;
  background-color: var(--theme-primaryColor);
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  color: var(--theme-primaryColor);
}

.side-modal {
  .modal {
    box-shadow: 0 15px 30px 0 rgb(34 41 47 / 11%), 0 5px 15px 0 rgb(34 41 47 / 8%);
    border-left: 1px solid rgba(34, 41, 47, 0.05);
  }
}

ul {
  .color-box {
    height: 24px;
    width: 24px;
    position: relative;
    margin: 0.3rem;
    border-radius: 0.5rem;
    cursor: pointer;
  }
}

.tsettings-modal {
  overflow: auto !important;

  .modal-backdrop {
    &.show {
      opacity: 0;
      display: none;
      z-index: 0;
    }
  }
}

.custom-modal {
  display: none;

  &.show {
    left: unset;
    right: 30px;
    top: 0;
    bottom: 0;
    width: 420px;
    z-index: 9999;

    @media screen and (max-width: 450px) {
      right: 0;
      width: 100%;
    }

    .modal-dialog {
      transition: transform 0.3s ease-out;
    }
  }

  .modal-dialog {
    transition: transform 0.3s ease-out;
  }

  @media screen and (max-width: 450px) {
    width: 100%;
  }

  .modal-dialog {
    transform: translateX(400px);
  }

  &.show {
    .modal-dialog {
      transform: translateX(0);
    }
  }

  .modal-dialog {
    width: 450px !important;
    min-height: 100% !important;
    margin: 0;

    @media screen and (max-width: 450px) {
      width: 100% !important;
    }
  }

  .modal-content {
    border-radius: 0;
  }

  .btn-close {
    margin: 0 !important;
  }

  form {
    [type='radio']:checked {
      background-image: unset;
      background-color: var(--theme-primaryColor);
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }

    .switch-label {
      font-size: 0.857rem;
      color: #5e5873;
    }

    #upload-photo {
      opacity: 0;
      position: absolute;
      z-index: -1;
    }
  }
}

.css-tj5bde-Svg {
  fill: #858585 !important;
  width: 16px;
}

.DraftEditor-root {
  z-index: 0;
}

.side-panel {
  color: #6638ff;
}

.table-bordered {
  border: 1px solid #ebebeb;

  thead {
    tr {
      th {
        border: 1px solid #ddd;
        padding: 8px;
      }
    }
  }
}

.rdw-dropdown-optionwrapper {
  max-height: 144px;
}

.ck.ck-dropdown__panel.ck-dropdown__panel_se {
  height: 100px;
  overflow-y: auto;
  overflow-x: hidden;
}

/* width */
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Handle */
*::-webkit-scrollbar-thumb {
  background: #6638ff !important;
  border-radius: 6px;
}

.right-card-img {
  display: block;

  @media screen and (max-width: 1023px) {
    display: none;
  }
}

@media screen and (max-width: 992px) {
  .hide-lists .mobile-layout-ul {
    display: none;
  }

  .mobile-dropdown {
    display: block;
  }

  .show-lists .mobile-layout-ul {
    display: block;
  }
}

@media screen and (min-width: 993px) {
  .mobile-dropdown {
    display: none;
  }
}

.react-tagsinput-input {
  padding: 5px;
  font-size: 14px;
  font-family: unset;
}

.dashboard {
  .react-daterange-picker__inputGroup__input {
    padding: 0 1px !important;
    font-size: 14px !important;
  }
}

.css-1pahdxg-control {
  min-height: unset;
  height: 100%;
}

.paymentProvider {
  border-radius: 20px;
}

.memberlist-btn {
  background-color: #6638ff;
}

.modal-icon {
  font-size: 60px;
  color: rgb(226, 227, 227);
}

.memberlist-detail-table {
  vertical-align: baseline;
}

.course-download {
  color: #898c94;
}
.standbyBackground{
  background-color: rgb(255 237 213);
}
.standbyTextColor{
  color: rgb(249 115 22);
}
.event-timepicker{
.react-time-picker__wrapper{
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  border-color: #d1d5db;
  width: 100%;
    border-radius: 0.375rem;
    border-width: 1px;
}
.react-time-picker{
  width: 100%;
}
}
.custom-btn{
  border: 1px solid #eee;
  border-radius: 0.25rem !important;
  padding: 6px 12px;
}
#ckf-modal-body{
  iframe {
     display: block;
   }
 }
 .event-logo-img{
  width: 33%;
  max-height: 150px;
 }
 .event-banner-img {
  width: 100%;
  max-height: 300px;
}
.event-back-img {
  width: 100%;
  max-height: 300px;
}
.overflow-x-auto-sm {
  @media (max-width: 1136px) {
    overflow-x: auto;
  }
}
.microsoft-btn{
  background-color: #F24914;
  border: #F24914;
}
.separator-line {
  margin: 20px 0;
  border-top: 1px solid #ccc;
  position: relative; /* Required for absolute positioning */
}

.separator-text {
  position: absolute;
  top: -10px; 
  left: 50%;
  transform: translateX(-50%); 
  background-color: white; 
  padding: 0 10px; 
  font-family: Arial, sans-serif;
  font-size: 14px; 
  color: #999; 
}

@media (max-width: 640px) {
  .custom-form-check {
    display: block; /* Display as block on mobile screens */
  }
}