$color_1: #e69e41;
$background-color_1: #fff;
$background-color_2: rgb(241, 241, 241);
$background-color_3: #0e0d0d;

.icon-picker {
	display: inline-block;
	text-align: left;

	.pick-box-container {
		display: flex;
	}
}

.icon-picker-main {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: auto;
	border: 1px solid #ccc;
	border-radius: 8px;
	span {
		padding: 0.5rem;
	}
}

form {
	.nosubmit {
		border: none;
		padding: 0;
		width: 100%;
	}
}

input.nosubmit {
	border: 1px solid #555;
	width: 100%;
	padding: 9px 4px 9px 40px;
	background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat 13px center;
}

.selectedColor {
	background-color: #1266f1;
	color: white;
}

.pick-box {
	box-shadow: 0 0 3px rgb(86 96 117 / 70%);
	position: absolute;
	top: 100%;
	padding: 8px;
	left: 0;
	width: 400px;
	background-color: $background-color_1;
	border: 1px solid #ccc;
	border-radius: 4px;
	z-index: 1;
	overflow-y: scroll;
	height: 300px;
	width: 280px;
}

.popover-title {
	padding: 12px;
	font-size: 13px;
	line-height: 15px;
	border-bottom: 1px solid #ebebeb;
	background-color: #f7f7f7;
	margin: 0;
	border-radius: 5px 5px 0 0;
}

.iconpicker-search {
	margin: 0 0 2px 0;
	background-color: #FFFFFF;
	background-image: none;
	border: 1px solid #e5e6e7;
	border-radius: 0.4rem;
	color: inherit;
	display: block;
	padding: 6px 12px;
	transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
	width: 100%;
	font-size: 14px;
}

.p-rel {
	position: relative;
}

.m-l {
	margin-left: 8px;
}

.pick-box-main {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.icon-list {
	margin-top: 5px;

	.icon-item {
		display: inline-block;
		cursor: pointer;
		margin: 4px;
		box-shadow: 0 0 0 1px #ddd;
		width: 37px;
		height: 32px;
		text-align: center;
		border-radius: 4px;

		&:hover {
			background-color: #ddd;
		}
	}
}

.icon-item {
	.icon {
		line-height: 32px;
		display: inline-block;
	}
}