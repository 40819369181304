.rc-time-picker-input{
    padding: 18px !important;
    font-size: 0.875rem !important;
    border-radius: 0.375rem !important;
}
.ck-editor__editable_inline ol {
    padding: 0 30px !important;
}
.ck-editor__editable_inline ul {
    padding: 0 30px !important;
}
.ck.ck-dropdown .ck-dropdown__arrow {
    z-index: 0 !important;
}