@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {

    .btn-primary {
        @apply block w-full px-4 py-2 mt-4 text-sm font-medium text-center text-white border border-transparent rounded-lg mx-auto;
    }

    .btn-danger {
        @apply block w-full px-4 py-2 mt-4 text-sm font-medium text-center text-white border border-transparent rounded-lg mx-auto;
    }

    .input-style {
        @apply w-full px-4 py-2 text-sm border rounded-md focus:border-gray-400 focus:outline-none focus:ring-1 focus:ring-gray-300;
    }

    .form-box {
        @apply flex-1 h-full max-w-5xl mx-auto;
    }

    .box-side-image {
        @apply object-cover w-full h-full;
    }

    .card-container {
        @apply w-full flex-1 mt-5 mx-1 p-5 order-2 bg-white shadow-xl rounded-3xl sm:w-72 lg:w-full;
    }

    .page-wrapper {
        @apply flex items-center justify-center min-h-screen max-w-5xl mx-auto;
    }

    .top-logo {
        @apply align-top mx-auto mb-10;
    }

    .card-top-h {
        @apply mb-7 pb-7 flex items-center border-b border-gray-300;
    }

    .checkbox {
        @apply w-4 h-4 bg-gray-50 rounded border border-gray-300;
    }

    .flex-items {
        @apply flex items-center justify-between;
    }

    .sidebar {
        @apply h-full whitespace-nowrap overflow-x-hidden fixed z-10 top-0 bg-white shadow-2xl md:w-64 w-3/4 space-y-6 pt-6 px-0 inset-y-0 left-0 transform md:relative md:translate-x-0 transition duration-200 ease-in-out md:flex md:flex-col md:justify-between overflow-y-auto;
    }

    .side-menu {
        @apply flex items-center text-black py-2 transition duration-200;
    }

    .menu-icon {
        @apply w-10 p-2 bg-transparent rounded text-center;
    }

    .dashboard-card {
        @apply px-4 py-2 transition-shadow rounded-lg bg-white;
    }

    .notification {
        @apply absolute text-white h-5 w-5 flex-items justify-center rounded-full p-1;
    }

    .inner-menus {
        @apply p-1 pl-5 w-full text-sm font-normal flex items-center;
    }

    .action-menu {
        @apply dropdown-item text-sm py-2 pl-4 pr-8 font-normal block w-full whitespace-nowrap bg-transparent;
    }

    .dropdown-menu-ul {
        @apply dropdown-menu min-w-max absolute hidden bg-white z-50 float-left py-1 list-none text-left rounded-lg shadow mt-1 m-0 border-none;
    }

    .paging-link {
        @apply border-gray-300 hover:bg-gray-50 hidden md:inline-flex relative items-center px-4 py-2 text-sm font-medium;
    }

    .tabs-link {
        @apply nav-link block leading-tight border-0 px-6 py-3 my-2 hover:bg-gray-100 rounded-lg font-semibold mr-2;
    }

    .dismiss-btn {
        @apply text-xs inset-0 absolute bg-white btn-close box-content w-3 h-3 p-2 border-none rounded-md focus:outline-none hover:text-black hover:no-underline;
    }

    .switch-btn {
        @apply appearance-none w-14 rounded-full float-left h-7 align-top bg-no-repeat bg-contain bg-gray-300 focus:outline-none focus:ring-0 focus:ring-transparent cursor-pointer shadow-sm border-0;
    }

    .modal {
        @apply fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto;
    }

    .modal-content {
        @apply border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current;
    }

    .paging-btn {
        @apply relative inline-flex items-center px-2 py-2 rounded-full text-sm font-medium bg-gray-100;
    }

    input[type=file]::file-selector-button {
        @apply text-white bg-blue-500 cursor-pointer border-0 py-2 pl-3;
        margin-inline-start: -1rem;
        margin-inline-end: 1rem;
    }

    .dropdown-item {
        @apply text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent hover:bg-gray-100;
    }

    .action-dropdown {
        @apply min-w-max absolute hidden bg-white text-base z-50 float-left py-2 list-none text-left rounded-lg shadow-lg mt-1 m-0 bg-clip-padding border-none;
    }

    .channel-edit {
        @apply bg-white py-2 list-none text-left rounded-lg shadow-lg;
    }

    .form-check-input.radio-btn {
        @apply appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white focus:outline-none transition duration-200 my-1 align-top float-left mr-2 cursor-pointer;
    }

    .video-tab-link {
        @apply block font-medium text-xs leading-tight uppercase;
    }

    .accordion-btn {
        @apply flex items-center w-full py-4 px-5 text-base text-gray-800 text-left bg-white border-0 rounded-sm transition focus:outline-none;
    }

    .check-box-input {
        @apply appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white my-1 align-top cursor-pointer focus:outline-none;
    }

    .video-view-badge {
        @apply block py-1 px-2 leading-none text-center whitespace-nowrap align-baseline bg-gray-100 text-gray-500 rounded-full;
    }

    .badge {
        @apply inline-block py-1.5 px-2.5 leading-none text-center whitespace-nowrap align-baseline rounded-sm mr-2;
    }
}

@layer utilities {

    [type='checkbox']:checked,
    [type='radio']:checked {
        background-size: unset;
    }

    [type='checkbox']:checked {
        background-color: var(--theme-primaryColor) !important;
    }

    html,
    #root {
        --theme-primaryColor: #6638ff;
        --theme-secondaryColor: #605a75;
    }

    .dashboard-card {
        box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
    }

    .site-clr {
        color: var(--theme-primaryColor);
    }

    .btn-primary,
    .bg-primary {
        background-color: var(--theme-primaryColor);
    }

    .btn-primary {
        border: 1px solid var(--theme-primaryColor);
    }

    .btn-primary.discard-btn {
        background-color: transparent;
        border: 1px solid #ccc;
        color: #6e6b7b;
    }

    .btn-danger {
        background-color: transparent;
        border: 1px solid #fd0303;
        color: #f10b0b;
    }

    .btn-primary.borderonly-btn {
        border: 1px solid var(--theme-primaryColor);
        background: transparent;
        color: var(--theme-primaryColor);
        font-weight: 700;
    }

    .btn-primary.blue-border-btn {
        border: 1px solid var(--theme-primaryColor);
        background: transparent;
        color: var(--theme-primaryColor);
    }

    .btn-primary.bg-green {
        background-color: #28c76f;
        border-color: #28c76f;
    }

    .box-side-image {
        border-radius: 0 26px 26px 0;
    }

    .form-radius {
        border-radius: 26px;
    }

    .card-container {
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }

    .card-fonts {
        font-size: 12px;
        white-space: nowrap;
    }

    .dismiss-btn {
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }

    .accordion-button::after {
        width: 1rem;
        height: 0.8rem;
        background-size: 1rem;
    }

    .accordion-button:not(.collapsed) {
        color: inherit;
    }

    .nav-tabs .tabs-link.active {
        color: var(--theme-primaryColor);
    }

    .nav-tabs .nav-link {
        white-space: nowrap;
    }

    .dropdown-menu-ul {
        margin-top: 1rem !important
    }

    .cm-dropdown-menu-ul {
        margin-top: 0 !important;
    }

    .video-view-badge {
        font-size: 12px;
    }


    .ck {
        .btn {
            border: 1px solid #eee;
            padding: 8px;
            cursor: pointer;
        }
    }
  .addNewCourse{
    .modal-body {
        padding: 16px;

        .wrapper {
            display: flex;
            gap: 16px;

            .box-wrapper {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 8px;
                flex: 1;
                height: 115px;
                border: 1px solid #e5e7eb;
                cursor: pointer;

                &:hover,
                &.active {
                    border: 1px solid #60a5fa;
                }

                h6 {
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                    margin: 6px 0 0 0;
                }
            }
        }
    }
}
}