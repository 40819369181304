.member {
    .profile-name {
        span {
            background-color: #eeedfd;
        }
    }

    .speaker-browse-file {
        .fileUpload {
            overflow: unset;
        }
    }

    .member-list {
        border: 1px solid var(--theme-primaryColor);

    }

    .theme-light-clr {
        background-color: rgba(8, 136, 220, .12);
    }

    .suspend-btn {
        border: 1px solid #ea5455 !important;
        background-color: transparent;
        color: #ea5455 !important;
    }

    .nav-tabs .nav-link.active {
        border-color: var(--theme-primaryColor);
        box-shadow: 0 4px 18px -4px rgb(26 146 255);
        background-color: var(--theme-primaryColor);
        color: #fff !important;
        border-radius: 0.358rem;
        padding: 0.786rem 1.5rem;
    }

    ul.activity-info {
        list-style: none;
        margin: 0;
        padding: 0;
        position: relative;

        &:before {
            content: "";
            display: inline-block;
            width: 1px;
            background: #d8d8d8;
            position: absolute;
            left: 5px;
            top: 5px;
            height: calc(100% - -10px);
        }

        li {
            position: relative;
            padding-left: 2rem;
            height: 50px;

            &:before {
                content: "";
                display: inline-block;
                width: 12px;
                height: 12px;
                background: var(--theme-primaryColor);
                position: absolute;
                left: 0;
                top: 2px;
                border-radius: 10px;
            }
        }
    }

    .modal {
        form {
            label {
                font-size: .857rem;
                color: #5e5873;
            }
        }

        .form-switch .form-check-input {
            background-size: contain;
        }
    }

}

.export-dropdown {
    color: var(--theme-primaryColor);
    border-color: var(--theme-primaryColor);
}

.members-list {
    .bg-light-1 {
        color: var(--theme-primaryColor) !important;
        background-color: rgba(115, 103, 240, .12) !important;
    }

    .bg-light-3 {
        color: #ea5455 !important;
        background-color: rgba(234, 84, 85, .12) !important;
    }

    .bg-light-2 {
        color: #28c76f !important;
        background-color: rgba(40, 199, 111, .12) !important;
    }

    .bg-light-4 {
        color: #ff9f43 !important;
        background-color: rgba(255, 159, 67, .12) !important;
    }
}

.custm-category {
    display: inline-block;
    background-color: #e4e4e4;
    border: 1px solid #aaa;
    border-radius: 4px;
    cursor: default;
    margin-top: 5px;
    padding: 0 5px;
    margin-right: 5px;
}