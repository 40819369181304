.dashboard {
    .export-dropdown {
        color: var(--theme-primaryColor);
        border-color: var(--theme-primaryColor);
    }

    .bg-light {
        background-color: #f1f0fe;
    }

    .box1 {
        background-color: #1a92ff;
    }

    .box2 {
        background-color: #6610f2;
    }

    .box3 {
        background-color: #00cfe8;
    }

    .box4 {
        background-color: #28c76f;
    }

    .small-circle {
        width: .75rem;
        height: .75rem;
        display: inline-block;
        border-radius: 50%;
    }

    ul.activity-info {
        list-style: none;
        margin: 0;
        padding: 0;
        position: relative;

        &:before {
            content: "";
            display: inline-block;
            width: 1px;
            background: #d8d8d8;
            position: absolute;
            left: 5px;
            top: 5px;
            height: calc(100% - -10px);
        }

        li {
            position: relative;
            padding-left: 2rem;
            height: 122px;

            &:before {
                content: "";
                display: inline-block;
                width: 12px;
                height: 12px;
                background: var(--theme-primaryColor);
                position: absolute;
                left: 0;
                top: 2px;
                border-radius: 10px;
            }
        }
    }

    .react-daterange-picker__wrapper {
        border: 0;
    }

    .react-daterange-picker__button {
        padding: 0;

        &.react-daterange-picker__calendar-button {
            &::after {
                content: '\f078';
            }
        }

        svg {
            display: none;
        }
    }

    .react-daterange-picker__wrapper::before,
    .react-daterange-picker__calendar-button::after {
        font-family: FontAwesome;
        font-weight: normal;
        font-style: normal;
        text-decoration: none;
        color: var(--theme-primaryColor);
    }

    .react-daterange-picker__wrapper::before {
        content: '\f0f3';
        margin: 0 0 0 9px;
    }

    .react-daterange-picker__inputGroup {
        color: #7367f0;
        font-weight: bolder;
    }

    .bg-light-1 {
        background-color: #eeecfd;
        color: var(--theme-primaryColor);
    }

    .bg-light-2 {
        background-color: #e5f8ed;
        color: #43ce81;
    }

    .bg-light-3 {
        background-color: #fceaea;
        color: #ee7a7a;
    }

    .bg-light-4 {
        background-color: #fff3e8;
        color: #ffa44c;
    }

    .custom-h {
        max-height: 310px;
        overflow: auto;
    }

}

.react-daterange-picker__wrapper::before {
    display: none;
}

.dashboard-tiles {
    .dashboard-card {
        width: 100%;
        margin-right: 1rem;

        &:last-child {
            margin-right: 0;
        }
    }
}

.custom-h-card {
    height: 45px;
}

.sales-order-box {
    canvas {
        height: 240px !important;
    }
}