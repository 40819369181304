.videos-view {
    .nav-tabs {
        .nav-link {
            border-radius: 0;

            &:hover {
                background-color: transparent;
            }

            &.active {
                border-left: 3px solid;
                border-color: var(--theme-primaryColor);
                color: var(--theme-primaryColor);
            }
        }
    }

    .wrapper-class {
        border: 1px solid #f1f1f1;

        .rdw-editor-main {
            padding: 6px 5px 0;
        }
    }

    .toolbar-class {
        border-bottom-width: 0px;
    }

    .time-btn {
        background-color: #f1f1f1 !important;
    }

    .videoEdit-tabs {
        .video-upload-btn {
            .btn-primary {
                border: 1px solid var(--theme-primaryColor);
                color: var(--theme-primaryColor);
                background-color: #fff;
                font-weight: 700;
            }
        }
    }

}

.tree-view p,
.tree-view i {
    cursor: pointer;
}

.speaker-dlt {
    right: -18px;
    top: -13px;

    i {
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }
}

.left-side-card {
    &.enable-video-div {
        .add-video {
            display: block;
        }

        .video-cards {
            display: none;
        }
    }

    .video-cards {
        .card-heading {
            min-height: 40px;
        }

        h6.main-title {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .nav-tabs {
        border-color: var(--theme-primaryColor);
    }

    .nav-tabs .nav-link {
        color: var(--theme-primaryColor);
        border-bottom: 0;
        border-color: var(--theme-primaryColor);

        &.active {
            background-color: #d1e9ff;
        }
    }
}

.list-content {
    vertical-align: middle;
}

.browse-file {
    label {
        background-color: transparent;
        display: table;
        border: 1px dashed var(--theme-primaryColor);
        color: var(--theme-primaryColor);
    }

    input[type="file"] {
        display: none;
    }
}

.browse-file-dropzone {
    border: 1px dashed var(--theme-primaryColor);
    color: var(--theme-primaryColor);
    width: 600px;
}

.browse-file-dropzone .file-drop{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    box-sizing: border-box !important;
    height: 200px !important;
}

.channel-settings {

    .modal,
    .modal-dialog,
    .modal-content {
        height: 100%;
    }

    .modal {
        left: unset;
        right: 0;
        top: 0;
        bottom: 0;
        width: 420px;

        @media screen and (max-width: 450px) {
            width: 100%;
        }

        &.fade {
            .modal-dialog {
                transform: translateX(350px);
            }

            &.show {
                .modal-dialog {
                    transform: translateX(0);
                }
            }
        }

        .modal-dialog {
            width: 420px !important;
            min-height: 100% !important;
            margin: 0;

            @media screen and (max-width: 450px) {
                width: 100% !important;
            }
        }

        .modal-content {
            border-radius: 0;
        }

        .btn-close {
            margin: 0 !important;
        }

        form {
            [type='radio']:checked {
                background-image: unset;
                background-color: var(--theme-primaryColor);
                box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
            }

            .switch-label {
                font-size: .857rem;
                color: #5e5873;
            }

            #upload-photo {
                opacity: 0;
                position: absolute;
                z-index: -1;
            }
        }
    }

    /* width */
    .modal::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }

    /* Handle */
    .modal::-webkit-scrollbar-thumb {
        background: var(--theme-primaryColor);
    }
}

.speaker-browse-file {
    .fileUpload {
        position: relative;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        overflow: hidden;
    }

    #uploadFile {
        background: none;
        border: 0;
    }

    .fileUpload input.upload {
        position: absolute;
        top: 0;
        right: 0;
        margin: 0;
        padding: 0;
        cursor: pointer;
        opacity: 0;
        filter: alpha(opacity=0);
        display: block;
    }
}

.highlight-reel,.video-categories {
    .nav-tabs {
        .nav-link {
            border-radius: 0;

            &:hover {
                background-color: transparent;
            }

            &.active {
                border-left: 3px solid;
                border-color: var(--theme-primaryColor);
                color: var(--theme-primaryColor);
            }
        }
    }
}

.custom-theme {
    .nav-tabs {
        &.horizontal-tabs-top {
            .nav-link {
                &.active {
                    border-color: var(--theme-primaryColor);
                    box-shadow: 0 4px 18px -4px rgb(26 146 255);
                    background-color: var(--theme-primaryColor);
                    color: #fff !important;
                    border-radius: 0.358rem;
                    padding: 0.786rem 1.5rem;
                }
            }
        }
    }
}

.box-text-main {
    min-height: 40px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.rounded-circle {
    .circle {
        display: inline-flex;
        margin-right: 4px;
        height: 12px;
        width: 12px;
        border-radius: 50%;
        background: red;
    }
}

.hr-line-dashed {
    border-top: 1px dashed #e7eaec;
    color: #ffffff;
    background-color: #ffffff;
    height: 1px;
    margin: 20px 0;
}

.catalogueMain {
    .leftSidebar {
        transition: 0.5s;
    }

    &.cataloguetoggle {
        .leftSidebar {
            width: 0;
            margin: 0;
            border: 0;

            * {
                display: none;
            }
        }

        .rightsideCard {
            width: 100%;
        }
    }
    &.sidebartoggle{
        .leftSidebar {
            width: 100%;
            margin: 0;
            border: 0;
        }
        .rightsideCard {
            width: 0;
            margin:0;
            border:0;
            * {
                display: none;
            }
        }
    }
}

.analytics-box {
    border-radius: 5px;
    padding: 15px 20px;
    margin-bottom: 10px;
    margin-top: 10px;
    color: #ffffff;

    &.navy-bg {
        background-color: #4B8AFF;
    }

    &.purple-bg {
        background-color: #7B33CE;
    }

    &.light-green-bg {
        background-color: #00D2DB;
    }
}

.gd-info {
    position: relative;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 500px;
    box-shadow: 0 5px 10px 0 rgb(50 50 50 / 15%);
    width: 40px;
    height: 40px;
}

.range-slider {
    background: var(--theme-primaryColor);
    border-radius: 0.0625rem;
    width: 28px;
    top: -1.5rem;
    font-size: 13px;

    &::before {
        border: solid 0.25rem var(--theme-primaryColor);
        border-bottom-color: transparent;
        border-left-color: transparent;
        border-right-color: transparent;
        content: "";
        height: 0;
        left: 50%;
        margin-left: -0.25rem;
        position: absolute;
        top: calc(100% + 0.25rem);
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        width: 0;
    }
}

.ChannelImageHeight{
    height: 20%;
    width: 20%;
}