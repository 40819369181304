.card-main {
  grid-template-columns: auto 0.5fr 1fr;
  .card-img-head {
    width: 200px;
  }
  .card-img {
    width: 200px;
    height: 120px;
  }
}
.card-grid-main {
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  .card-img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
}
.list-grid-wrap {
  min-width: 50px;
  .list-image {
    min-width: 24px;
  }
  .active {
    background-color: #d1e9ff;
  }
}
.tab-box {
  @media (max-width: 1299px) {
    width: 1200px;
  }
}

.custom-name {
  width: 50%;
  // background-color: aqua;
}
.header-wrap { 
  &:nth-child(1) {
    min-width: 256px;
  }
  &:nth-child(2) {
    flex: 1;
  }
  &:nth-child(3) {
    flex: 2;
  }
  &:nth-child(4) {
    width: 86px;
  }
}
.custom-blank-wrap {
  width: 30%;
}
.body-cell {
  display: flex;
  align-items: center;
  &:nth-child(1) {
    min-width: 256px;
  }
  &:nth-child(2) {
    flex: 1;
  }
  &:nth-child(3) {
    flex: 2;
  }
  &:nth-child(4) {
    width: 86px;
  }
}
.table-body {
  .rounded-md {
    margin: 2px;
  }
}
.custom-col {
  // flex: 1;
  // background-color: chartreuse;
  width: 20%;
}
.chanal-select {
  width: 100%;
}
.file-upload {
  .browse-file-dropzone {
    width: 100%;
  }
}
.min-w-65 {
  min-width: 65px;
}
.empty-list-msg {
  display: flex;
  justify-content: center;
}
.btns-main{
  height: 158px;
}
.video-image{
  height: 400px;
}
.video-upload-btn{
  background: #00000030;
  backdrop-filter: blur(3px);
}
.channel-setting-image:hover .video-upload-btn{
  display: flex;
}
.model-main{
  z-index: 9999;
  background-color: #00000060;
}
.collection-main{
  height: calc(100vh - 200px);
}
.standbyColor{
  color: rgb(249, 115, 22);
  background-color: rgb(255, 237, 213);
}