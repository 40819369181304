.videos-view {
    .nav-tabs {
        .nav-link {
            border-radius: 0;

            &:hover {
                background-color: transparent;
            }

            &.active {
                border-left: 3px solid;
                border-color: var(--theme-primaryColor);
                color: var(--theme-primaryColor);
            }
        }
    }

    .wrapper-class {
        border: 1px solid #f1f1f1;

        .rdw-editor-main {
            padding: 6px 5px 0;
        }
    }

    .toolbar-class {
        border-bottom-width: 0px;
    }

    .time-btn {
        background-color: #f1f1f1 !important;
    }

    .videoEdit-tabs {
        .video-upload-btn {
            .btn-primary {
                border: 1px solid var(--theme-primaryColor);
                color: var(--theme-primaryColor);
                background-color: #fff;
                font-weight: 700;
            }
        }
    }

}

.tree-view p,
.tree-view i {
    cursor: pointer;
}

.speaker-dlt {
    right: -18px;
    top: -13px;

    i {
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }
}

.left-side-card {
    &.enable-video-div {
        .add-video {
            display: block;
        }

        .video-cards {
            display: none;
        }
    }

    .video-cards {
        .card-heading {
            min-height: 40px;
        }

        h6.main-title {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .modal {
            &.videoViews {
                display: block;
                position: fixed;
                top: 0;
                left: 0;
                z-index: 1050;
                width: 100vw;
                height: 100vh;
                background-color: rgba(0, 0, 0, 0.5);

                .modal-dialog {
                    margin: 1.75rem auto;
                    transform: unset;

                    .modal-content {
                        .modal-header {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            padding: 16px;
                            position: relative;

                            h5 {
                                font-weight: 600;
                                font-size: 20px;
                                line-height: 28px;
                            }

                            .close {
                                position: absolute;
                                top: -10px;
                                right: -10px;
                                bottom: 0;
                                box-sizing: content-box;
                                height: 0.75rem;
                                width: 0.75rem;
                                border-radius: 0.375rem;
                                font-size: 1rem;
                                border-style: none;
                                --tw-bg-opacity: 1;
                                background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
                                padding: 0.5rem;
                                line-height: 1rem;
                                box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

                                span {
                                    height: 10px;
                                }
                            }
                        }

                        .modal-body {
                            padding: 16px;

                            .wrapper {
                                display: flex;
                                gap: 16px;

                                .box-wrapper {
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: center;
                                    align-items: center;
                                    gap: 8px;
                                    flex: 1;
                                    height: 115px;
                                    border: 1px solid #e5e7eb;
                                    cursor: pointer;

                                    &:hover,
                                    &.active {
                                        border: 1px solid #60a5fa;
                                    }

                                    h6 {
                                        font-size: 16px;
                                        font-weight: 400;
                                        line-height: 24px;
                                        margin: 6px 0 0 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &.fade {
                &:not(.show) {
                    opacity: 1;
                }

            }
        }
    }

    .nav-tabs {
        border-color: var(--theme-primaryColor);
    }

    .nav-tabs .nav-link {
        color: var(--theme-primaryColor);
        border-bottom: 0;
        border-color: var(--theme-primaryColor);

        &.active {
            background-color: #d1e9ff;
        }
    }
}

.list-content {
    vertical-align: middle;
}

.browse-file {
    label {
        background-color: transparent;
        display: table;
        border: 1px dashed var(--theme-primaryColor);
        color: var(--theme-primaryColor);
    }

    input[type="file"] {
        display: none;
    }
}

.browse-file-dropzone {
    border: 1px dashed var(--theme-primaryColor);
    color: var(--theme-primaryColor);
    width: 600px;
}

.channel-settings {

    .modal,
    .modal-dialog,
    .modal-content {
        height: 100%;
    }

    .modal {
        left: unset;
        right: 0;
        top: 0;
        bottom: 0;
        width: 420px;

        @media screen and (max-width: 450px) {
            width: 100%;
        }

        &.fade {
            .modal-dialog {
                transform: translateX(350px);
            }

            &.show {
                .modal-dialog {
                    transform: translateX(0);
                }
            }
        }

        .modal-dialog {
            width: 420px !important;
            min-height: 100% !important;
            margin: 0;

            @media screen and (max-width: 450px) {
                width: 100% !important;
            }
        }

        .modal-content {
            border-radius: 0;
        }

        .btn-close {
            margin: 0 !important;
        }

        form {
            [type='radio']:checked {
                background-image: unset;
                background-color: var(--theme-primaryColor);
                box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
            }

            .switch-label {
                font-size: .857rem;
                color: #5e5873;
            }

            #upload-photo {
                opacity: 0;
                position: absolute;
                z-index: -1;
            }
        }
    }

    /* width */
    .modal::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }

    /* Handle */
    .modal::-webkit-scrollbar-thumb {
        background: var(--theme-primaryColor);
    }
}

.speaker-browse-file {
    .fileUpload {
        position: relative;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        overflow: hidden;
    }

    #uploadFile {
        background: none;
        border: 0;
    }

    .fileUpload input.upload {
        position: absolute;
        top: 0;
        right: 0;
        margin: 0;
        padding: 0;
        cursor: pointer;
        opacity: 0;
        filter: alpha(opacity=0);
        display: block;
    }
}

.highlight-reel {
    .nav-tabs {
        .nav-link {
            border-radius: 0;

            &:hover {
                background-color: transparent;
            }

            &.active {
                border-left: 3px solid;
                border-color: var(--theme-primaryColor);
                color: var(--theme-primaryColor);
            }
        }
    }
}

.custom-theme {
    .nav-tabs {
        &.horizontal-tabs-top {
            .nav-link {
                &.active {
                    border-color: var(--theme-primaryColor);
                    box-shadow: 0 4px 18px -4px rgb(26 146 255);
                    background-color: var(--theme-primaryColor);
                    color: #fff !important;
                    border-radius: 0.358rem;
                    padding: 0.786rem 1.5rem;
                }
            }
        }
    }
}

.box-text-main {
    min-height: 40px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.rounded-circle {
    .circle {
        display: inline-flex;
        margin-right: 4px;
        height: 12px;
        width: 12px;
        border-radius: 50%;
        background: red;
    }

    .circle-green {
        display: inline-flex;
        margin-right: 4px;
        height: 12px;
        width: 12px;
        border-radius: 50%;
        background: green;
    }
}

.hr-line-dashed {
    border-top: 1px dashed #e7eaec;
    color: #ffffff;
    background-color: #ffffff;
    height: 1px;
    margin: 20px 0;
}

.catalogueMain {
    .leftSidebar {
        transition: 0.5s;
    }

    &.cataloguetoggle {
        .leftSidebar {
            width: 0;
            margin: 0;
            border: 0;

            * {
                display: none;
            }
        }

        .rightsideCard {
            width: 100%;
        }
    }
}

.analytics-box {
    border-radius: 5px;
    padding: 15px 20px;
    margin-bottom: 10px;
    margin-top: 10px;
    color: #ffffff;

    &.navy-bg {
        background-color: #4B8AFF;
    }

    &.purple-bg {
        background-color: #7B33CE;
    }

    &.light-green-bg {
        background-color: #00D2DB;
    }
}

.gd-info {
    position: relative;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 500px;
    box-shadow: 0 5px 10px 0 rgb(50 50 50 / 15%);
    width: 40px;
    height: 40px;

}

div.react-tagsinput--focused {
    border-color: rgba(156, 163, 175, 1);
}

.react-tagsinput-tag {
    background-color: #6638ff !important;
    color: #ffffff !important;
    border: none !important
}

#QuizModal,
#UpdateQuizModal,
#UpdateStarRatingModal,
#UpdateMultipleChoiceModal,
#UpdateOpenTextModal,
#QuizResultModal,
#SurveyModal,
#MultipleChoiceResultModal,
#MultipleChoiceModal,
#StarRatingModal,
#OpenTextModal {
    .questions-side-card {
        // max-height: 180px;
        // overflow-y: auto;

        input.input-style {
            border: 0;
            border-bottom: 1px solid #ccc;
            border-radius: 0;
        }
    }
}

#selectThumbnailModal,
#QuizModal,
#UpdateQuizModal,
#UpdateStarRatingModal,
#UpdateMultipleChoiceModal,
#UpdateOpenTextModal,
#SurveyModal,
#MultipleChoiceResultModal,
#QuizResultModal,
#MultipleChoiceModal,
#StarRatingModal,
#OpenTextModal {
    .modal-dialog {
        max-width: 50rem;
    }
}

.icon-picker {
    .jWujcK {
        border: 1px solid #ccc;
        width: 100%;
    }
}

.d-tree {
    ul {
        &.d-tree-container {
            max-height: 30vw;
            min-height: 20vw;
            overflow-y: auto;

            .rstcustom__rowWrapper:hover {
                opacity: 1;
            }

            .rstcustom__collapseButton,
            .rstcustom__expandButton {
                z-index: unset;
            }

            .rstcustom__expandButton::after,
            .rstcustom__collapseButton::after {
                font-family: 'Font Awesome 6 Free';
                border: none;
                border-left-width: 0;
                border-right-width: 0;
                font-weight: 900;
                color: #99999e;
            }

            .rstcustom__expandButton::after {
                content: '\f054';
                font-family: 'Font Awesome 6 Free';
                position: relative;
            }

            .rstcustom__collapseButton::after {
                content: '\f078';
                position: absolute;
                margin-top: -8px;
            }

            .rstcustom__collapseButton:focus::after,
            .rstcustom__expandButton:focus::after {
                -webkit-filter: none;
                filter: none;
            }

            .rstcustom__lineBlock {
                width: 23px !important;
            }

            .rstcustom__rowLabel::before {
                content: '\f07b';
                font-family: 'Font Awesome 6 Free';
                border-top-color: transparent;
                position: absolute;
                color: #99999e;
            }
        }



        /* Track & Thumb */
        &.d-tree-container::-webkit-scrollbar-track,
        &.d-tree-container::-webkit-scrollbar-thumb {
            background: transparent;
        }

        .taller-channel-tree {
            ul {
                &.d-tree-container {
                    max-height: 100%;
                    overflow-y: auto;

                    .rstcustom__rowWrapper:hover {
                        opacity: 1;
                    }

                }

            }
        }

        &.channel-edit {
            margin-right: 10px;
        }
    }
}

.colorpick-eyedropper-input-trigger {
    display: none !important;
}

.fontIcon-picker {
    border: 1px solid #ccc;
}

.add-video.h-fit {
    height: fit-content;
}

.ck-editor__editable {
    min-height: 300px;
}

.privacyStatement .ck-editor__editable {
    min-height: 100px;
    height: 100px;
}

.dragDropMain {
    .leftSidebar {
        width: 100%;

        @media screen and (min-width: 1224px) {
            width: 60%;
        }

        button,
        input.input-style {
            width: 100%;
            margin-left: 0;
            margin-right: 0;
        }
    }

    .rightsideCard {
        width: 100%;

        @media screen and (min-width: 1224px) {
            width: 40%;
        }

        .grid {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        .header-action-btns {
            display: block;
        }
    }

    .d-tree ul.d-tree-container .rstcustom__rowLabel::before {
        content: '\f7a4';
        position: relative;
        margin-right: 7px;
        font-weight: 999;
    }

    .d-tree ul.d-tree-container .rstcustom__rowWrapper {
        cursor: grab;
    }


}

.duration {
    border: 2px solid grey;
}

.pollResult-background {
    background-color: #dff0d8;
}

.quiz-disable {
    background: #EFEFEF;
}

.quiz-question-list {
    border: 1px solid #ccc;
    padding: 9px;

}

.rc-time-picker-clear-icon {
    display: none;
}

.mediaPlayerBackgroundImageHeight {
    height: 200px;
    width: 100%;
}

.create-poll-menu {
    inset: 0px auto auto 0px !important;
    margin-top: 0 !important;
}

.thumbnail-main {
    .btns-main {
        opacity: 0;
        visibility: hidden;
        background-color: hsla(0, 0%, 100%, .4) !important;
        transition: visibility 0.6s linear, opacity 0.6s ease;
    }

    .thumb-wrap {
        img {
            height: 17.7vw;
            object-fit: cover;
        }

        &:hover {
            .btns-main {
                opacity: 1;
                visibility: visible;
            }
        }
    }

    .channel-setting-image {
        img {
            height: 9rem;
            object-fit: cover;
        }

        &:hover {
            .btns-main {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}

.list-view-thumb {
    width: 132px;
    height: 74px;
}

.live-studio-heading {
    color: #6b64d1;
}

.live-studio-border {
    border-radius: 3rem;
}

.liveEventBorder {
    border-bottom: 1px solid;
}

.badge-pollAttended {
    background: #d1dade;
    border-radius: 11px;
    width: 20px;
    height: 20px;
}

#lc-color-picker {
    z-index: 1200 !important
}

.option-border {
    border-color: #605A75;
}

.ai-icon {
    position: relative;
    bottom: 10px;
}

.ai-background {
    background-color: lightgrey;
    height: 40px;
    border-radius: 4px;
}

.moderator-interactivity {
    height: 60vh
}

td.truncate {
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

td.truncate:hover {
    max-width: none;
    white-space: normal;
    overflow: visible;
}

.polls-refresh {
    font-size: 25px;
    margin-right: 25px;
    margin-top: 5px;
    color: #6b64d1;
}

.categories-table-wrapper {
    .multiline-wrap-text {
        &:hover {
            white-space: normal;
            word-wrap: break-word;
            max-width: 300px;
        }
    }
}